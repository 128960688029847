@import './../../../../scss/theme-bootstrap';

.product-grid-wrapper {
  .product-grid {
    &__content {
      .product-grid {
        display: flex !important;
        &__item {
          box-sizing: border-box;
          flex: 1 0 auto;
          flex-wrap: wrap;
          height: auto !important;
          .product-brief {
            text-align: center;
            padding: 0 5px;
            margin: 0 auto;
            @include breakpoint($medium-up) {
              padding: 0;
            }
            @include breakpoint($medium-down) {
              .product-grid--carousel--small-1 & {
                padding: 0;
              }
            }
            &__review-snippet {
              .pr-snippet-stars-container {
                .pr-snippet-rating-decimal {
                  display: none;
                }
              }
            }
            &__price,
            &__shades-wrapper,
            &__sizes-wrapper {
              margin: 10px 0;
              display: flex;
              flex: 0 1 auto;
              min-width: 0;
            }
            &__shades-wrapper {
              vertical-align: bottom;
              .product-shade-picker {
                &__shades {
                  display: flex;
                  height: 33px;
                }
                &__shade {
                  padding-#{$rdirection}: 0.5em;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  margin-bottom: 0;
                  box-shadow: inset 0 0 0 2px $color-white;
                  @include breakpoint($medium-up) {
                    border-radius: 50%;
                  }
                  &[data-sku-list-order='0'] {
                    order: 1;
                  }
                  &[data-sku-list-order='1'] {
                    order: 2;
                  }
                  &[data-sku-list-order='2'] {
                    order: 3;
                  }
                  &[data-sku-list-order='3'] {
                    order: 4;
                  }
                  &.active {
                    border: 1px solid $color-black;
                  }
                }
              }
            }
            &__price {
              padding-#{$rdirection}: 12px;
              height: 24px;
              margin: 0 0 15px;
              align-items: center;
            }
            &__container {
              display: flex;
              flex-direction: column;
              height: 100%;
              position: inherit;
            }
            &__footer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
            }
            &__footer-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
            }
            &__sized-detail,
            &__shaded-detail {
              display: flex;
              flex-direction: row;
              flex: 1;
              align-items: flex-end;
              justify-content: center;
            }
            &__name,
            &__description,
            &__tagline {
              width: 95%;
              margin: 0 auto;
              text-align: center;
              @include breakpoint($medium-up) {
                width: 80%;
              }
            }
            &__name {
              @include header--small;
              font-family: $font--avenir-medium;
            }
            &__cta-add-to-bag {
              display: flex;
              flex: 1 0 auto;
              flex-flow: column;
              .product {
                &__inventory-status-container {
                  @include breakpoint($medium-up) {
                    width: 100%;
                  }
                }
              }
              .button {
                width: 92%;
                font-size: 14px;
                padding: 12px 25px 11px;
                position: absolute;
                #{$ldirection}: 12px;
                bottom: 0;
                text-align: center;
                display: flex;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                min-width: 0;
                height: auto;
                max-height: 45px;
                @include breakpoint($medium-up) {
                  #{$ldirection}: 15px;
                }
                .product {
                  &__inventory-status-container {
                    @include breakpoint($medium-up) {
                      width: auto;
                    }
                  }
                }
              }
            }
            .product-brief__img--alt {
              opacity: 0;
              position: absolute;
              top: 0;
              transition: opacity 0.3s;
              &:hover {
                opacity: 1;
              }
            }
          }
          &--tout {
            justify-content: center;
            margin: 0 auto;
            .contextual-links-region {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.product-brief__header {
  position: relative;
}

.product-brief__footer {
  @include breakpoint($medium-down) {
    .product-grid--carousel--small-1 & {
      opacity: 0;
    }
    .product-grid__item--slide.slick-active & {
      opacity: 1;
    }
  }
}

.product-brief__footer-container {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  @include breakpoint($medium-up) {
    width: 90%;
  }
  @include breakpoint($large-up) {
    width: 80%;
  }
  @include breakpoint($medium-down) {
    .product-grid--carousel--small-1 & {
      width: 100%;
    }
  }
}

.product-brief__subline,
.product-brief__name {
  a {
    text-decoration: none;
  }
}

.product-brief__subline {
  @include t2;
  font-size: 12px;
  margin: 0 0 2px 0;
  font-family: $font--avenir-light;
  text-transform: uppercase;
  @include breakpoint($large-up) {
    font-size: 14px;
  }
  .product-quickshop & {
    @include breakpoint($large-up) {
      @include header--small;
      font-family: $font--avenir-light;
      margin: 0;
    }
  }
}

.product-brief__name {
  @include breakpoint($large-up) {
    @include header--small;
    font-family: $font--avenir-medium;
    margin: 0 0 5px 0;
  }
  .product-grid__item--slide & {
    @include header--small;
    font-family: $font--avenir-book;
    display: block;
    margin: 0 0 5px 0;
  }
  .product-quickshop & {
    font-family: $font--avenir-heavy;
    @include breakpoint($large-up) {
      @include header--large;
      font-family: $font--avenir-book;
      margin-#{$ldirection}: -2px;
      margin-bottom: 0;
    }
  }
}

// By default, it should be hidden. If you need this shown use the parent selector below or override accordingly.
.product-brief__description {
  display: none;
  font-family: $font--avenir-book;
  margin-top: 8px;
  .display-product-brief-description & {
    display: block;
  }
}

.product-brief__price {
  font-family: $font--avenir-black;
  margin: 9px 0;
  @include breakpoint($large-up) {
    margin: 5px 0 7px 0;
  }
  .product-grid__item--slide & {
    font-size: 16px;
    margin: 5px 0 7px 0;
  }
  .product__price {
    &--non-sale {
      text-decoration: line-through;
    }
    &--sale {
      color: $color-red;
    }
  }
}

.product-brief__tagline {
  font-family: $font--avenir-light;
  color: $color-gray;
  margin: 0 0 10px 0;
  @include breakpoint($large-up) {
    font-size: 14px;
    color: $color-black;
    .no-touchevents & {
      margin: 0;
    }
  }
  .product-grid__item--slide & {
    font-size: 16px;
  }
}

.product-brief__quickshop-launch {
  position: absolute;
  #{$ldirection}: 5px;
  bottom: 0;
  width: 94%;
  @include breakpoint($medium-up) {
    width: auto;
    #{$ldirection}: 50%;
    transform: translate(-50%, 0);
  }
  @include breakpoint($large-up) {
    display: none;
    .touchevents & {
      display: inline-block;
    }
  }
  @include breakpoint($medium-down) {
    .product-grid--carousel--small-1 & {
      position: static;
      width: auto;
      margin-top: 10px;
      transform: none;
    }
  }
}

// Show overlay on hover for desktop no-touchevents
.product-brief__extras {
  opacity: 0;
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity $transition-duration $transition-effect;
  .product-brief__quickshop-launch {
    display: inline-block;
    position: absolute;
    bottom: 10%;
    #{$ldirection}: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    transition: bottom $transition-duration $transition-effect, background $transition-duration $transition-effect;
  }
  .product-brief__favorites {
    position: absolute;
    top: 0;
    #{$rdirection}: 35px;
    z-index: 10;
    transition: top $transition-duration $transition-effect;
  }
}

.no-touchevents .product-brief:hover {
  @include breakpoint($large-up) {
    .product-brief__extras {
      opacity: 1;
      .product-brief__quickshop-launch {
        bottom: 20%;
      }
      .product-brief__favorites {
        top: 38px;
      }
    }
  }
}

.product-brief__extras-link {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

//
// Global Product Elements
//
.product__image {
  max-width: 100%;
  .product-brief & {
    margin: 0 0 8px 0;
    @include breakpoint($large-up) {
      margin-bottom: 30px;
    }
    &-badge {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: 70px;
      height: 44px;
      top: 0;
      @include breakpoint($medium-up) {
        width: 110px;
        height: 69px;
        #{$ldirection}: 20px;
      }
      &--award {
        background-image: url('/media/images/products/badges/badge_award.png');
      }
      &--exclusive {
        background-image: url('/media/images/products/badges/badge_exclusive.png');
      }
      &--limitededition {
        background-image: url('/media/images/products/badges/badge_limitededition.png');
      }
      &--new {
        background-image: url('/media/images/products/badges/badge_new.png');
      }
      &--stylistpick {
        background-image: url('/media/images/products/badges/badge_stylistpick.png');
      }
      &--topseller {
        background-image: url('/media/images/products/badges/badge_topseller.png');
      }
    }
  }
  @include breakpoint($medium-down) {
    .product-grid--carousel--small-1 & {
      max-width: 75%;
      transition: max-width 0.4s ease;
    }
    .product-grid__item--slide.slick-active & {
      max-width: 100%;
    }
  }
}

.product-flag__container {
  font-family: $font--avenir-light;
  font-size: 12px;
  text-align: $ldirection;
  @include breakpoint($large-up) {
    font-size: 14px;
  }
  .product-brief & {
    position: absolute;
    top: 10px;
    #{$ldirection}: 10px;
    @include breakpoint($large-up) {
      top: 40px;
      #{$ldirection}: 35px;
    }
  }
}

.product__button--add-favorites {
  text-decoration: none;
  font-size: 24px;
  &:hover {
    text-decoration: none;
  }
}

.pret-active {
  .mpp-container {
    .product-grid {
      .product-grid__content {
        .product-grid__item {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 60%;
          }
        }
        .product-grid__item.grid__item--last-col {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 40%;
          }
        }
      }
    }
  }
  .basic-tout__image {
    &--hide {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

.section-collections,
.section-bb-clips {
  .collections-landing-nav {
    background: $color-lighter-gray;
    padding: 2px 0;
    .custom-collection-nav-item {
      > .menu {
        &__link {
          padding: 5px 6px 3px;
          line-height: 2.5;
          font-size: 13px;
          background: $color-white;
          border: 1px solid $color-black;
          text-decoration: none;
          font-family: $font--avenir-heavy;
          letter-spacing: 0.01em;
          @include breakpoint($medium-up) {
            padding: 7px 10px 5px;
            line-height: 2.7;
            font-size: 15px;
          }
        }
      }
    }
    & > .basic-formatter {
      &__items {
        width: 100%;
        max-width: 875px;
        margin: 15px auto;
        text-align: center;
        & > .basic-formatter {
          &__item {
            display: inline-block;
            width: auto;
            margin: 0 1px;
          }
        }
      }
    }
  }
  .collections-header-bg {
    background: $color-lighter-gray;
    padding: 25px 0 15px;
  }
}

.section-bb-thickening {
  .custom-tout-header {
    .basic-formatter {
      &__header {
        background: $color-lighter-gray;
        margin-bottom: 0;
        padding: 15px 12px 12px;
        .header--small {
          letter-spacing: 0.05em;
          font-size: 20px;
        }
      }
    }
    .tabbed-slider {
      margin-top: 0;
      &__header {
        display: none;
      }
    }
  }
}

.section-bb-university,
.section-bb-live-events {
  .basic-formatter {
    &__header {
      background: $color-background-gray;
      padding-top: 15px;
      padding-bottom: 5px;
    }
  }
  .pricing-column {
    .priced-item {
      font-family: $font--avenir-heavy;
    }
  }
}
